<script setup lang="ts">
import { ref } from 'vue'

const showNavbar = ref(false)

const menu = [
  { name: 'Features', href: '/features' },
  { name: 'About', href: '/about' },
  { name: 'Pricing', href: '/pricing' },
  { name: 'Key Dates', href: '/key-dates' },
  { name: 'Contact', href: '/contact' },
]
</script>
<template>
  <!-- ========== HEADER ========== -->
  <header class="flex flex-wrap lg:justify-start lg:flex-nowrap z-50 w-full py-7">
    <nav
      class="relative max-w-7xl w-full flex flex-wrap lg:grid lg:grid-cols-12 basis-full items-center px-4 lg:px-8 mx-auto"
      aria-label="Global">
      <div class="lg:col-span-3">
        <!-- Logo -->
        <a href="/" aria-label="Footy Tipping">
          <FTLogo size="small" />
        </a>
        <!-- End Logo -->
      </div>


      <!-- Button Group -->
      <div class="flex items-center gap-x-2 ms-auto py-1 lg:ps-6 lg:order-3 lg:col-span-3">
        <Link href="/login">
        <Button type="button" variant="secondary" class="hover:text-gray-600">
          Sign in
        </Button>
        </Link>
        <Link href="/register">
        <!-- <Button type="button" variant="default" class="hover:text-gray-600">
          Create a comp
        </Button> -->
        </Link>

        <div class="lg:hidden">
          <button type="button" @click="showNavbar = !showNavbar"
            class="size-[38px] flex justify-center items-center text-sm font-semibold rounded-xl border border-gray-200 text-black hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-neutral-700 dark:hover:bg-neutral-700">
            <svg class="hs-collapse-open:hidden flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round">
              <line x1="3" x2="21" y1="6" y2="6" />
              <line x1="3" x2="21" y1="12" y2="12" />
              <line x1="3" x2="21" y1="18" y2="18" />
            </svg>
            <svg class="hs-collapse-open:block hidden flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round">
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
        </div>
      </div>
      <!-- End Button Group -->

      <!-- Collapse -->
      <div id="navbar-collapse-with-animation"
        class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow lg:block lg:w-auto lg:basis-auto lg:order-2 lg:col-span-6">
        <div
          class="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:justify-center md:items-center md:gap-y-0 md:gap-x-7 md:mt-0">
          <div v-for="item in menu" :key="item.href">
            <Link :class="[
              'relative inline-block text-black hover:text-gray-600 dark:text-white dark:hover:text-neutral-300',
              {
                'before:absolute before:bottom-0.5 before:start-0 before:-z-[1] before:w-full before:h-1 before:bg-primary':
                  $page.url.startsWith(item.href)
              }
            ]" :href="item.href">
            {{ item.name }}
            </Link>
          </div>
        </div>
      </div>
      <!-- End Collapse -->
    </nav>
  </header>
  <!-- ========== END HEADER ========== -->
  <transition enter-active-class="transition-all duration-300 ease-out overflow-hidden"
    enter-from-class="max-h-0 opacity-0" enter-to-class="max-h-[300px] opacity-100"
    leave-active-class="transition-all duration-300 ease-in overflow-hidden"
    leave-from-class="max-h-[300px] opacity-100" leave-to-class="max-h-0 opacity-0">
    <div v-if="showNavbar" class="bg-neutral-50 text-black/80 text-sm lg:hidden">
      <ul class="mb-6 shadow-sm">
        <li>
          <Link href="/features">Features</Link>
        </li>
        <li>
          <Link href="/about">About</Link>
        </li>
        <li>
          <Link href="/pricing">Pricing</Link>
        </li>
        <li>
          <Link href="/key-dates">Key dates</Link>
        </li>
        <li>
          <Link href="/contact">Contact</Link>
        </li>
      </ul>
    </div>
  </transition>
</template>

<style scoped>
li {
  @apply h-12 flex items-center border-b px-6;
  list-style-type: none;
}

li:hover {
  @apply bg-secondary cursor-pointer;
}
</style>
