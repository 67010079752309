<template>
  <!-- ========== FOOTER ========== -->
  <footer class="mt-auto w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
    <hr class="py-10" />
    <!-- Grid -->
    <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 mb-10">
      <div class="col-span-full hidden lg:col-span-1 lg:block">
        <Link class="flex-none text-xl font-semibold dark:text-white" href="/" aria-label="Brand">footy tipping</Link>
        <p class="mt-3 text-xs sm:text-sm text-gray-600 dark:text-neutral-400">© 2024 footy tipping.</p>
      </div>
      <!-- End Col -->

      <div>
        <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-neutral-100">Product</h4>

        <div class="mt-3 grid space-y-3 text-sm">
          <p>
            <Link
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200"
              href="/features">Features</Link>
          </p>
          <p>
            <Link
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200"
              href="/pricing">Pricing</Link>
          </p>
          <p>
            <Link
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200"
              href="/about">About</Link>
          </p>
        </div>
      </div>
      <!-- End Col -->

      <div>
        <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-neutral-100">Company</h4>

        <div class="mt-3 grid space-y-3 text-sm">
          <p>
            <Link
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200"
              href="/about">About us</Link>
          </p>
          <p>
            <Link
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200"
              href="/contact">Help & Support</Link>
          </p>
        </div>
      </div>
      <!-- End Col -->

      <div>
        <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-neutral-100">Resources</h4>

        <div class="mt-3 grid space-y-3 text-sm">
          <p>
            <Link
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200"
              href="/key-dates">Key dates</Link>

          </p>
          <p>
            <Link
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200"
              href="/sitemap">Sitemap</Link>
          </p>
        </div>
      </div>
      <!-- End Col -->

      <div>

        <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-neutral-100">Legal</h4>

        <div class="mt-3 grid space-y-3 text-sm">
          <p>
            <Link
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200"
              href="/privacy-policy">Privacy policy</Link>
          </p>
          <p>
            <Link
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200"
              href="/terms">Terms and conditions</Link>
          </p>
        </div>
      </div>
      <!-- End Col -->
    </div>
    <!-- End Grid -->

  </footer>
  <!-- ========== END FOOTER ========== -->
</template>
